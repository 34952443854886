.container{
    position:relative;
    margin-left:auto;
    margin-right:auto;
    padding-right:15px;
    padding-left:15px
}
@media (min-width: 476px){
    .container{
        padding-right:15px;
        padding-left:15px
    }
}
@media (min-width: 768px){
    .container{
        padding-right:15px;
        padding-left:15px
    }
}
@media (min-width: 992px){
    .container{
        padding-right:15px;
        padding-left:15px
    }
}
@media (min-width: 1200px){
    .container{
        padding-right:15px;
        padding-left:15px
    }
}
@media (min-width: 476px){
    .container{
        width:100%
    }
}
@media (min-width: 768px){
    .container{
        width:720px;
        max-width:100%
    }
}
@media (min-width: 992px){
    .container{
        width:960px;
        max-width:100%
    }
}
@media (min-width: 1200px){
    .container{
        width:1140px;
        max-width:100%
    }
}
@media (min-width: 1400px){
    .container{
        width:1340px;
        max-width:100%
    }
}

.iconTitleDiv {
    text-align: center;
}

.card-title-tracking {
    display: flex;
    justify-content: space-between;
}

.tracking-item-list-antd .ant-list-item-meta-content .ant-list-item-meta-title {
    margin-top: 0;
}